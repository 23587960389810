$(document).ready(function() {
    
    // Stock Slider - HomePage
    $('.banner').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.slideSet').children('.slide').clone();

            scope.find('.slideSet').flickity({
                cellAlign: 'right',
                initialIndex: 0,
                groupCells: '100%',
                pageDots: true,
                autoPlay: 5000,
                prevNextButtons: true,
            });            
        });
    });

    // Stock Slider - Home Slider
    $('.single-slider').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.single-sliderRow').children('.single-slider-cell').clone();

            scope.find('.single-sliderRow').flickity({
                autoPlay: 7000, 
                wrapAround: true,               
                groupCells: true,
                cellAlign: 'center',
                pageDots: true,
                prevNextButtons: true,
            });            
        });
    });

    // Stock Slider - Multiple Product Slider
    $('.group-slider').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.sliderRow').children('.slider-cell').clone();

            scope.find('.sliderRow').flickity({
                autoPlay: 7000, 
                groupCells: true,                
                pageDots: true,
                prevNextButtons: true,
                wrapAround: true,
                cellAlign: 'center',
            });            
        });
    });

    // Thumbnail Slider - LED Product Details Page
    $('.thumbnailSlider').each(function(){
        var scope = $(this);

        // 1st carousel, main
        $('.larg-slider').flickity({
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            wrapAround: true,
            autoPlay: false,
        });

        // 2nd carousel, navigation
        $('.larg-slider-nav').flickity({
            asNavFor: '.larg-slider',
            contain: true,
            pageDots: false,
            prevNextButtons: true,
            wrapAround: true,
            autoPlay: false,
        });
    });

    // Fancybox Slider - Product Details Page
    if($('[data-fancybox]').length > 0){        
        $('[data-fancybox]').fancybox({ 
            loop : false,
            keyboard : true, 
            arrows : true,      
            toolbar : true,
            protect : true,
            animationEffect : "zoom",
            
            buttons : [        
            'close'
            ],
        });
    }    
});

// Owl Slider - Case Studies Slider
$(document).ready(function() {

    var owl1 = $(".desktop-owl-slider");
    owl1.owlCarousel({
    autoplay:true,
    transitionStyle : "fade",
    items:1,
    dots:false,
    mouseDrag:true,
    loop:true,
    nav:true
    });		  

    var owl2 = $(".mob-owl-slider");
    owl2.owlCarousel({
        autoplay:true,
        transitionStyle : "fade",
        items:1,
        dots:false,
        mouseDrag:true,
        loop:true,
        nav:false
    });        

    $('.owl-next').click(function() {
        owl2.trigger('next.owl.carousel');
        owl1.trigger('next.owl.carousel');
    });

    $('.owl-prev').click(function() {
        owl2.trigger('prev.owl.carousel');
        owl1.trigger('prev.owl.carousel');
    });
    
    
    $('.caseStudies-slider').owlCarousel({
        loop:true,
        center: true,
        nav:true,
        margin:30,
        responsiveClass:true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1,
                loop:true,
                nav:false                
            },
            600:{
                items:2,
                loop:true,
                center: false, 
                nav:true
            },
            1000:{
                items:3,
                autoplay:false,
                center: false,                
                nav:true
            }            
        }
    });

});