$(function(){
       
    // ACCORDION
    $('.acc .acc-head').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('.acc-info').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('.acc-info').stop().slideToggle();  
    });

    // ACCORDION
    if ($(".stepBlocks .acc").length > 0) { 
        $(".stepBlocks .acc:first-child").addClass('active');
        $(".stepBlocks .acc:first-child").find("footer").css("display", "block");
    }
    $('.acc header').click(function(){
        var parent = $(this).parent();
        //parent.siblings().find('footer').hide();
        //parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('footer').stop().slideToggle();
    });


    // ACCORDION Toggle 2
    $('.listBlock a.cta').click(function(){
        var parent = $(this).parent();
        parent.find('.content .details').slideToggle(); 
    });    

    // Sticky Header Script
    $(window).scroll(function() {
        if ($(this).scrollTop() > 1){  
            $('header').addClass("sticky");
        }
        else{
            $('header').removeClass("sticky");
        }
    });

    // On Click More Text Script
    $('.more-text').removeClass('active').addClass('inactive');
    $('.more-link').click(function(){
        $('.more-text.active').each(function(){
            $(this).removeClass('active').addClass('inactive');
        });
        $(this).prev().removeClass('inactive').addClass('active');
    });
    $('.less-link').click(function(){
        $(this).prev().prev().removeClass('active').addClass('inactive');
    });

    // scroll body to 0px on click
    $('#back-top a').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    
});





    







